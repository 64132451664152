import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Grid,
  Paper,
  Card,
  CardMedia,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Link,
  // ExpansionPanel,
  // ExpansionPanelSummary,
  // ExpansionPanelDetails
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import CheckIcon from '@material-ui/icons/Check';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import pass from './pass.png';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '25px'
  },
  subTitle: {
    paddingBottom: '25px'
  },
  content: {
    '& h2': {
      paddingTop: '25px'
    },
    '& h3': {
      paddingTop: '25px',
      paddingBottom: '5px'
    }
  },
  indexContainer: {
    maxWidth: '250px',
    marginTop: '25px',
    marginBottom: '25px',
  },
  indexIcon: {
    color: theme.palette.icon
  },
  quote: {
    backgroundColor: theme.palette.warning.light,
    borderLeft: '5px solid',
    borderLeftColor: theme.palette.warning.main,
    padding: '0.5rem'
  },
  subHeader: {
    color: theme.palette.text.primary
  },
  preview: {
    maxWidth: '300px',
    margin: '25px',
  }
}));

const Help = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container>
          <Grid lg={9} item>
            <Typography variant="h1" gutterBottom>
              Getting Started
            </Typography>
            <Typography variant="body1" gutterBottom>
              This document describes how to use the Illuma Shield Demo to evalulate your voice.
            </Typography>
            <Paper className={classes.indexContainer}>
                <List dense>
                  <ListItem>
                    <ListItemText>
                      <Link href="#beforestart">Before you start</Link>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <Link href="#usingdemo">Using the Demo Site</Link>
                    </ListItemText>
                  </ListItem>
                </List>
            </Paper>
            <div className={classes.content}>
              {
                //eslint-disable-next-line jsx-a11y/anchor-is-valid
              }<a name="beforestart">
              <Typography variant="h2" gutterBottom>
                Before you start
              </Typography>
              </a>
              <Typography variant="h3" gutterBottom>
                Create your enrollments
              </Typography>
              <Typography variant="body1" gutterBottom>
                Place calls to <strong><NumberFormat value={sessionStorage.getItem('did_number')} displayType={'text'} format="+# (###) ###-####" /></strong> using your registered phone number. Each enrollment should meet the following requirements.
              </Typography>
              <List subheader={
                <ListSubheader component="div" className={classes.subHeader}>
                  Minimum Requirements
                </ListSubheader>
              }>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon />
                  </ListItemIcon>
                  <ListItemText>Minimum of 5 phone calls</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                  <CheckIcon />
                  </ListItemIcon>
                  <ListItemText>Speak for a minimum duration of 60 seconds</ListItemText>
                </ListItem>
              </List>
              <blockquote className={classes.quote}>
                <Typography variant="body1">
                  You may choose to fabricate a conversation or to read a script of your choice.
                </Typography>
              </blockquote>
              {
                //eslint-disable-next-line jsx-a11y/anchor-is-valid
              }<a name="usingdemo">
              <Typography variant="h2" gutterBottom>
                Using the Demo Site
              </Typography>
              </a>
              <Typography variant="h3" gutterBottom>
                Real-Time Authentication
              </Typography>
              <Typography variant="body1" gutterBottom>
                Place calls to <strong><NumberFormat value={sessionStorage.getItem('did_number')} displayType={'text'} format="+# (###) ###-####" /></strong> using your registered phone number and speak until you see a pass or fail decision.
              </Typography>
              <Card className={classes.preview}>
                <CardMedia
                  component="img"
                  alt="Pass Result"
                  image={pass}
                  title="Pass Result"
                />
                <CardContent>
                  <Typography
                    color="inherit"
                    variant="caption"
                    align="center"
                    display="block"
                  >
                    Authentication Dashboard
                  </Typography>
                </CardContent>
              </Card>
              <blockquote className={classes.quote}>
                <Typography variant="body1">
                  You may choose to fabricate a conversation or to read a script of your choice.
                </Typography>
              </blockquote>
            </div>
          </Grid>
          <Grid lg={3} item>
            <Grid spacing={2} container>
              <Grid item>
                <Card>
                  <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    Have a Question?
                  </Typography>
                  <Typography variant="body1">
                    Send an email to <a href="mailto:support@illuma-labs.com">support@illuma-labs.com</a> or call us at <strong>(972) 217-7106 ext 2</strong> with any questions you may have.
                  </Typography>
                  </CardContent>
                </Card>
              </Grid>
              {/* <Grid item>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      FAQ
                    </Typography>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>What do I do when I am finished with my calls?</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                          sit amet blandit leo lobortis eget.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>What is some other question?</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                          sit amet blandit leo lobortis eget.
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </CardContent>
                </Card>
              </Grid> */}
            </Grid>
          </Grid>
      </Grid>
    </div>
  );
};

export default Help;
