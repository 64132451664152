import React from 'react';
import {
    Button,
    CssBaseline,
    TextField,
    Typography,
    Container,
    Box,
    Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import AuthState from '../../../../utils/authstate';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    error: {
      marginTop: theme.spacing(1),
      textAlign: 'center'
    }
}));

const SignupSchema = Yup.object().shape({
    email: Yup.string()
        .email('​Email is not a valid email')
        .required('Email is required'),
    password: Yup.string()
        .required('Password is required')
});

function Copyright() {
    return (
      <Typography variant="body2" color="primary" align="center">
        {'© '}
        {new Date().getFullYear()}
        {' Illuma Labs, Inc. All rights reserved | '}
        <Link color="secondary" href="https://www.illuma-labs.com/terms-of-use">
          Terms of Use
        </Link>
      </Typography>
    );
  }

const LoginForm = props => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h1" color="primary">
                    Sign In
                </Typography>
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { setSubmitting, setFieldError }) => {
                        Auth.signIn(values.email, values.password)
                        .then(user => {
                            setSubmitting(false);
                            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                                props.onChangeForm({formName: 'newPassword', cognitoUser: user, email: values.email});
                            } else if (user.challengeName) {
                                console.log('[ERROR] unknown challengeName ' + user.challengeName);
                                setFieldError('general', 'An unknown error has occured');
                            } else {
                                Auth.currentUserInfo()
                                .then(res => {
                                    if (res && res.attributes) {
                                        var apiKey = res.attributes['custom:apikey'];
                                        sessionStorage.setItem('authstate.apiKey', apiKey);
                                    } else {
                                        console.log('[ERROR] apiKey not in attributes');
                                        setFieldError('general', 'Your user is not configured properly');
                                    }

                                    Auth.currentSession().then((res) => {
                                        if (res) {
                                            var idPayload = res.getIdToken().decodePayload();
                                            if (idPayload && idPayload["did_number"]) {
                                                sessionStorage.setItem('did_number', idPayload["did_number"])
                                                AuthState.setAuthenticated(true);
                                                props.onChangeForm({formName: 'redirectAfterLogin'});
                                            } else {
                                                console.log('[ERROR] did_number not in payload');
                                                setFieldError('general', 'Your user is not configured properly');
                                            }
                                        }
                                    });
                                })
                                .catch(err => {
                                    console.error(err);
                                    setFieldError('general', 'An unknown error has occured');
                                });
                            }
                        }).catch(e => {
                            console.log(e);
                            if (e.code === 'NotAuthorizedException' || e.code === 'UserNotFoundException') {
                                setFieldError('general', e.message);
                            } else if (e.code === 'PasswordResetRequiredException') {
                                props.onChangeForm({formName: 'resetPassword', email: values.email});
                            } else {
                                setFieldError('general', 'An unknown error has occured');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                        });
                    }}
                >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid
                }) => (
                    <div>
                        <div className={classes.error}>
                            <Typography component="h1" variant="h5" color="error">
                                {errors.general}
                            </Typography>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email ? errors.email : ""}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={touched.password && Boolean(errors.password)}
                                helperText={touched.password ? errors.password : ""}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={!isValid || isSubmitting}
                            >
                                Sign In
                            </Button>
                        </form>
                    </div>
                )}
                </Formik>
            </div>
            <Box mt={8}>
            <Copyright />
            </Box>
        </Container>
    );
}

export default LoginForm;