import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Grid
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    oops: {
        fontSize: '10rem'
    }
}));

const NotFound = () => {
    const classes = useStyles();
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item>
                <center>
                <Typography variant="h1" className={classes.oops} gutterBottom>
                    OOPS!
                </Typography>
                <Typography variant="h2" gutterBottom>
                    Error 404 : Page Not Found
                </Typography>
                <Link to="/">Return to Home Page</Link>
                </center>
            </Grid>
        </Grid>
    );
};

export default NotFound;