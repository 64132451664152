import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthState from '../../utils/authstate';
import { Auth } from 'aws-amplify';

const SignOut = props => {
    AuthState.signOut();
    Auth.signOut();
    
    return (
        <Redirect to="/" />
    );
}

export default SignOut;