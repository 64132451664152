import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, List, ListItem, ListItemIcon } from '@material-ui/core';
import { Link } from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';
import logo from '../../../../shield.svg';

const useStyles = makeStyles(theme => ({
    root: {

    },
    drawer: {
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(11)+5,
        },
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: '#1266C7',
    },
    icon: {
        color: '#FFFFFF',
    },
    selectedListItem: {
        borderStyle: 'solid',
        borderLeftWidth: '0.3rem',
        borderLeftColor: '#00bcf4',
        paddingLeft: '1.3rem !important',
        paddingRight: '1.3rem !important',
    },
    logoContainer: {
        paddingTop: '1rem',
        textAlign: 'center'
    },
    logo: {
    },
    listItems: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
    },
    button: {
        padding: '1.5rem',
    }
}));

const Sidebar = props => {
    const { open, variant, to, selected } = props;
    const classes = useStyles();

    const pages = [
        {
            key: 0,
            title: 'Dashboard',
            href: '/dashboard',
            icon: <HomeIcon fontSize="large" classes={{ root: classes.icon }} />
        }
    ];

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((linkProps, ref) => (
            <Link to={to} {...linkProps} innerRef={ref} />
            )),
        [to],
    );

    return (
        <Drawer
            anchor="left"
            open={open}
            variant={variant}
            classes={{ paper: classes.drawer }}
        >
            <div className={ classes.logoContainer }>
                <img src={logo} className={ classes.logo } alt="logo" />
            </div>
            <List className={clsx(classes.root)}>
            {pages.map(page => (
                <ListItem key={page.key} button selected={selected === page.title} className={clsx(classes.listItems, selected === page.title ? classes.selectedListItem : "")} component={renderLink} to={page.href}>
                    <ListItemIcon>{page.icon}</ListItemIcon>
                </ListItem>
            ))}
            </List>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
};

export default Sidebar;
