import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthState from '../../utils/authstate';

const AppRoute = props => {
  const { layout: Layout, component: Component, title: Title, ...rest } = props;
  let fromUrl = props.location.pathname + props.location.search;
  let loginRedirectUrl = '/signin?from=' + encodeURIComponent(fromUrl);

  if (props.isProtected === 'true' && !AuthState.isAuthenticated) {
    return (
      <Redirect to={loginRedirectUrl} />
    );
  } else {
    if (props.layout) {
      return (
        <Route
          {...rest}
          render={matchProps => (
            <Layout title={Title}>
              <Component {...matchProps} />
            </Layout>
          )}
        />
      );
    } else {
      return (
        <Route
          {...rest}
          render={matchProps => (
            <Component {...matchProps} />
          )}
        />
      );
    }
  }
};

AppRoute.propTypes = {
  component: PropTypes.any.isRequired,
  title: PropTypes.string,
  path: PropTypes.string,
};

export default AppRoute;
