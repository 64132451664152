class AuthState {
    get isAuthenticated() {
        return sessionStorage.getItem('authstate.isLoggedIn') === 'true';
    }

    setAuthenticated(value) {
        sessionStorage.setItem('authstate.isLoggedIn', value);
    }

    signOut() {
        sessionStorage.removeItem('authstate.isLoggedIn');
        sessionStorage.removeItem('authstate.apiKey');
    }
}

export default new AuthState();
