import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { LatestCalls, ActiveCall, TotalCalls, PhoneInfo, TotalEnrollments } from './components';
import { API } from 'aws-amplify';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const componentIsMounted = useRef(true);
  const [componentData, setComponentData] = useState({
      totalCalls: 0,
      totalEnrolledAccounts: 0
  });

  const handleUpdate = event => {
    console.log('updating call metrics');
    var myInit = {
        headers: {},
        queryStringParameters: {},
        response: true
    };

    myInit['headers']['x-api-key'] = sessionStorage.getItem("authstate.apiKey");
    API.get('Services', '/v1/analytics/calls/summary', myInit).then(response => {
        if (response.data) {
            var body = response.data;
            if (componentIsMounted.current) {
              setComponentData({
                  totalCalls: body.totalCalls,
                  totalEnrolledAccounts: body.totalEnrolledAccounts
              });
            }
        }
    }).catch(error => {
        console.log('error:'+error);
    });
  };

  useEffect(() => {
    handleUpdate();

    return function cleanup() {
        componentIsMounted.current = false;
    };
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          lg={4}
          sm={12}
          xs={12}
        >
          <PhoneInfo />
        </Grid>
        <Grid
          item
          lg={4}
          sm={6}
          xs={12}
        >
          <TotalCalls total={componentData.totalCalls} />
        </Grid>
        <Grid
          item
          lg={4}
          sm={6}
          xs={12}
        >
          <TotalEnrollments total={componentData.totalEnrolledAccounts} />
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
        >
          <ActiveCall />
        </Grid>
        <Grid
          item
          lg={8}
          xs={12}
        >
          <LatestCalls />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
