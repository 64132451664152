import React, { useState } from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import {
    Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoginForm, NewPasswordForm, ResetPasswordForm, PasswordWasReset } from './components';
import logo from '../../logo.svg';

const useStyles = makeStyles(theme => ({
    quoteGridItem: {
        backgroundColor: '#1266C7',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    logo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '100%',
        width: '25rem'
    },
    formGridItem: {
        position: 'relative'
    },
    formContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
}));

let cognitoUser = null;
let email = '';

const SignIn = props => {
    const classes = useStyles();
    const [activeForm, setActiveForm] = useState('login');
    const redirectUrl = queryString.parse(props.location.search).from ? queryString.parse(props.location.search).from : '/';

    const changeForm = (obj) => {
        if (obj.cognitoUser) {
            cognitoUser = obj.cognitoUser;
        }
        if (obj.email) {
            email = obj.email;
        }
        setActiveForm(obj.formName);
    };

    if (activeForm === 'login') {
        return (
            <Grid className={classes.grid} container justify="center" style={{ minHeight: '100vh', maxWidth: '100%' }}>
                <Grid item className={classes.quoteGridItem} md={6}>
                    <img src={logo} className={classes.logo} alt="logo" />
                </Grid>
                <Grid item md={6} xs={12} className={classes.formGridItem}>
                    <div className={classes.formContainer}>
                        <LoginForm onChangeForm={changeForm} user={cognitoUser} />
                    </div>
                </Grid>
            </Grid>
        );
    } else if (activeForm === 'newPassword') {
        return (
            <Grid className={classes.grid} container justify="center" style={{ minHeight: '100vh', maxWidth: '100%' }}>
                <Grid item className={classes.quoteGridItem} md={6}>
                    <img src={logo} className={classes.logo} alt="logo" />
                </Grid>
                <Grid item md={6} xs={12} className={classes.formGridItem}>
                    <NewPasswordForm onChangeForm={changeForm} email={email} user={cognitoUser} />
                </Grid>
            </Grid>
        );
    } else if (activeForm === 'resetPassword') {
        return (
            <Grid className={classes.grid} container justify="center" style={{ minHeight: '100vh', maxWidth: '100%' }}>
                <Grid item className={classes.quoteGridItem} md={6}>
                    <img src={logo} className={classes.logo} alt="logo" />
                </Grid>
                <Grid item md={6} xs={12} className={classes.formGridItem}>
                    <div className={classes.formContainer}>
                        <ResetPasswordForm onChangeForm={changeForm} email={email} />
                    </div>
                </Grid>
            </Grid>
        );
    } else if (activeForm === 'redirectAfterLogin') {
        return (
            <Redirect to={redirectUrl} />
        );
    } else if (activeForm === 'passwordWasReset') {
        return (
            <Grid className={classes.grid} container justify="center" style={{ minHeight: '100vh', maxWidth: '100%' }}>
                <Grid item className={classes.quoteGridItem} md={6}>
                    <img src={logo} className={classes.logo} alt="logo" />
                </Grid>
                <Grid item md={6} xs={12} className={classes.formGridItem}>
                    <PasswordWasReset />
                </Grid>
            </Grid>
        );
    }
};

export default SignIn;