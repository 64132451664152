import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery, Divider } from '@material-ui/core';
import { Sidebar, Topbar } from './components';

const useStyles = makeStyles(theme => ({
    root: {
    },
    shiftContent: {
        paddingLeft: theme.spacing(11)+5
    },
    content: {
        
    }
}));

const Main = props => {
    const { children, title } = props;

    const classes = useStyles();
    const theme = useTheme();

    const [openSidebar] = useState(false);

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });

    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.shiftContent]: isDesktop
            })}
        >
            <Sidebar
                open={shouldOpenSidebar}
                variant={isDesktop ? 'persistent' : 'temporary'}
                selected={title}
            />
            <Topbar title={title} />
            <Divider />
            <main className={classes.content}>
            {children}
            </main>
        </div>
    );
};
    
    Main.propTypes = {
      children: PropTypes.node
    };
    
    export default Main;
    