import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { Main as MainLayout } from './layouts';
import { AppRoute } from './components';
import {
  Dashboard as DashboardView,
  Signin as SigninView,
  Signout as SignoutView,
  Help as HelpView,
  NotFound as NotFoundView
} from './views';
import './assets/scss/index.scss';

const App = (props) => {
  return (
    <Router>
      <Switch>
        <Redirect
          exact
          from="/"
          to="/dashboard"
        />
        <AppRoute
          component={DashboardView}
          exact
          isProtected="true"
          layout={MainLayout}
          title="Dashboard"
          path="/dashboard"
        />
        <AppRoute
          component={HelpView}
          isProtected="true"
          exact
          layout={MainLayout}
          title="Help"
          path="/help"
        />
        <AppRoute
          component={SigninView}
          exact
          path="/signin"
        />
        <AppRoute
          component={SignoutView}
          exact
          path="/signout"
        />
        <AppRoute
          component={NotFoundView}
        />
      </Switch>
    </Router>
  );
}

export default App;
