import React from 'react';
import {
    Button,
    CssBaseline,
    TextField,
    Typography,
    Container
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    error: {
      marginTop: theme.spacing(1),
      textAlign: 'center'
    }
}));

const SignupSchema = Yup.object().shape({
    verificationCode: Yup.string()
        .required('Verification code is required'),
    password: Yup.string()
        .required('Password is required')
        .min(8, "Password must contain at least 8 characters")
        .matches(/(?=.*[a-z])/, 'Password must contain at least 1 uppercase character')
        .matches(/(?=.*[A-Z])/, 'Password must contain at least 1 uppercase  character')
        .matches(/(?=.*[0-9])/, 'Password must contain at least 1 number')
        .matches(/(?=.[!@#$%^&])/, 'Password must contain at least one special character'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref("password")], "Password does not match")
});

const ResetPasswordForm = props => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    We have sent as password reset code by email. Enter it below to reset your password.
                </Typography>
                <Formik
                    initialValues={{ verificationCode: '', password: '', confirmPassword: '' }}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { setSubmitting, setFieldError }) => {
                        Auth.forgotPasswordSubmit(
                            props.email,
                            values.verificationCode,
                            values.password
                        ).then(data => {
                            props.onChangeForm({formName: 'passwordWasReset'});
                        }).catch(e => {
                            console.log(e);
                            if (e.code === 'CodeMismatchException') {
                                setFieldError('general', 'Invalid verification code provided, please try again.');
                            } else if (e.code === 'ExpiredCodeException') {
                                setFieldError('general', 'Invalid code provided, please request a code again.');
                            } else {
                                setFieldError('general', 'An unknown error has occured');
                            }
                        }).finally(() => {
                            setSubmitting(false);
                        });
                    }}
                >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={classes.error}>
                            <Typography component="h1" variant="h5" color="error">
                                {errors.general}
                            </Typography>
                        </div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="verificationCode"
                            label="Code"
                            name="verificationCode"
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.verificationCode}
                            error={touched.verificationCode && Boolean(errors.verificationCode)}
                            helperText={touched.verificationCode ? errors.verificationCode : ""}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="New Password"
                            type="password"
                            id="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            error={touched.password && Boolean(errors.password)}
                            helperText={touched.password ? errors.password : ""}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Enter New Password Again"
                            type="password"
                            id="confirmPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                            helperText={touched.confirmPassword ? errors.confirmPassword : ""}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={!isValid || isSubmitting}
                        >
                            Sign In
                        </Button>
                    </form>
                )}
                </Formik>
            </div>
        </Container>
    );
}

export default ResetPasswordForm;