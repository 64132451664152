import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import App from './App';
import theme from './theme';
import * as serviceWorker from './serviceWorker';
import Amplify, { Auth } from 'aws-amplify';

// console.log(process.env.REACT_APP_IDENTITY_POOL_ID);
// console.log(process.env.REACT_APP_USER_POOL_ID);
// console.log(process.env.REACT_APP_CLIENT_ID);
// console.log(process.env.REACT_APP_SERVICE_ENDPOINT);
// console.log(process.env.REACT_APP_WSS_ENDPOINT);

Amplify.configure({
    "aws_project_region": process.env.REACT_APP_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_REGION,
    "aws_user_pools_id": process.env.REACT_APP_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_CLIENT_ID,
    "oauth": {},
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_REGION, 
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_USER_POOL_ID, 
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    },
    API: {
        endpoints: [
            {
                name: "Services",
                endpoint: process.env.REACT_APP_SERVICE_ENDPOINT,
                custom_header: async () => {
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }
        ]
    }
});

//Amplify.Logger.LOG_LEVEL = 'DEBUG';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
