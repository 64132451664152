import React from 'react';
import {
    Button,
    CssBaseline,
    TextField,
    Typography,
    Container
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import AuthState from '../../../../utils/authstate';
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    error: {
      marginTop: theme.spacing(1),
    }
}));

const SignupSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(8, "Password must contain at least 8 characters")
        .matches(/(?=.*[a-z])/, 'Password must contain at least 1 uppercase character')
        .matches(/(?=.*[A-Z])/, 'Password must contain at least 1 uppercase  character')
        .matches(/(?=.*[0-9])/, 'Password must contain at least 1 number')
        .matches(/(?=.[!@#$%^&])/, 'Password must contain at least one special character'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref("password")], "Password does not match")
});

const NewPasswordForm = props => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Enter a new password for {props.email}
                </Typography>
                <Formik
                    initialValues={{ password: '', confirmPassword: '' }}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { setSubmitting, setFieldError }) => {
                        Auth.completeNewPassword(
                            props.user,
                            values.password
                        ).then(user => {
                            Auth.currentUserInfo()
                            .then(res => {
                                if (res && res.attributes) {
                                    var apiKey = res.attributes['custom:apikey'];
                                    sessionStorage.setItem('authstate.apiKey', apiKey);
                                } else {
                                    console.log('[ERROR] apiKey not in attributes');
                                    setFieldError('general', 'Your user is not configured properly');
                                }

                                Auth.currentSession().then((res) => {
                                    if (res) {
                                        var idPayload = res.getIdToken().decodePayload();
                                        if (idPayload && idPayload["did_number"]) {
                                            sessionStorage.setItem('did_number', idPayload["did_number"])
                                            AuthState.setAuthenticated(true);
                                            props.onChangeForm({formName: 'redirectAfterLogin'});
                                        } else {
                                            console.log('[ERROR] did_number not in payload');
                                            setFieldError('general', 'Your user is not configured properly');
                                        }
                                    }
                                });
                            })
                            .catch(err => {
                                console.error(err);
                                setFieldError('general', 'An unknown error has occured');
                            });
                        }).catch(e => {
                            console.log(e);
                            setFieldError('general', 'An unknown error has occured');
                        }).finally(() => {
                            setSubmitting(false);
                        });
                    }}
                >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={classes.error}>
                            <Typography component="h1" variant="h5" color="error">
                                {errors.general}
                            </Typography>
                        </div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="New Password"
                            type="password"
                            name="password"
                            autoFocus
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            error={touched.password && Boolean(errors.password)}
                            helperText={touched.password ? errors.password : ""}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                            helperText={touched.confirmPassword ? errors.confirmPassword : ""}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={!isValid || isSubmitting}
                        >
                            Sign In
                        </Button>
                    </form>
                )}
                </Formik>
            </div>
        </Container>
    );
}

export default NewPasswordForm;