import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Badge, IconButton, Typography, Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import HelpIcon from '@material-ui/icons/Help';
import MoreIcon from '@material-ui/icons/MoreVert';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import logo from '../../../../shield-blue.svg';

const useStyles = makeStyles(theme => ({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logo: {
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }));

const Topbar = props => {  
  const { title } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const showMobileMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
    
  return (
      <Toolbar>
        <div className={ classes.logoContainer }>
            <img src={logo} className={ classes.logo } alt="logo" />
        </div>
        <Typography className={classes.title} variant="h2" noWrap>
          {title}
        </Typography>
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <Link to="/help">
            <IconButton aria-label="get help" color="default">
              <HelpIcon />
            </IconButton>
          </Link>
          <IconButton aria-label="no new notifications" color="default">
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Link to="/signout">
              <IconButton aria-label="delete" color="default">
                  <LogoutIcon />
              </IconButton>
          </Link>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-haspopup="true"
            color="inherit"
            onClick={showMobileMenu}
          >
            <MoreIcon />
          </IconButton>
          <Menu
            id="mobile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            variant="menu"
          >
            <Link to="/dashboard">
              <MenuItem onClick={handleClose}>Dashboard</MenuItem>
            </Link>
            <Link to="/help">
              <MenuItem onClick={handleClose}>Help</MenuItem>
            </Link>
          </Menu>
        </div>
      </Toolbar>
  );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func
};
  
export default Topbar;