import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Grid
} from '@material-ui/core';
import FailIcon from '@material-ui/icons/Cancel';
import PassIcon from '@material-ui/icons/CheckCircle';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  message: {
    color: '#404041',
    fontSize: '3rem',
    lineHeight: '3rem',
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

let completeTimer = null;
let lockDecision = false;
let socket = null;
let lastElapsedTime = 0;
let did_number = null;
let sub = null;

const ActiveCall = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  
  const componentIsMounted = useRef(true);
  const [message, setMessage] = useState("Loading");
  const [componentTitle, setComponentTitle] = useState("Active Call");
  const [connectionState, setConnectionState] = useState(null);

  useEffect(() => {
    if (!socket) {
      Auth.currentSession().then((res) => {
        var idPayload = res.getIdToken().decodePayload();
        if (idPayload && idPayload["did_number"]) {
          did_number = idPayload["did_number"];
          sub = idPayload["sub"];
          console.log('[INFO] did_number ' + did_number);
          handleConnect();
        } else {
          console.log('[ERROR] did_number not in payload');
        }
      });
    }
    
    return function cleanup() {
      if (socket !== null) {
        socket.close();
        socket = null;
      }
      componentIsMounted.current = false;
    };
  }, []);

  const handleReconnect = () => {
    console.log("reconnect");
    if (socket != null) {
      socket.close();
    }
    handleConnect();
  };

  const buttonState = state => {
    if (state === 'connected') {
      return "Connected";
    } else {
      return "Connect";
    }
  };

  const handleConnect = () => {
    setMessage("Connecting");
    socket = new WebSocket(process.env.REACT_APP_WSS_ENDPOINT);

    socket.onopen = function(event) {
      console.log(event);
      console.log("[open] Connection established");
      lastElapsedTime = 0;
      if (did_number) {
        console.log("Sending to subscription request server " + did_number);
        socket.send(JSON.stringify({"action":"subscribe","phone":did_number,"sub":sub}));
        setMessage("No Active Call");
        setComponentTitle("Active Call");
        setConnectionState('connected');
      } else {
        console.log("[ERROR] did_number was empty");
        setMessage("Failed to connect");
      }
    };
  
    socket.onclose = function(event) {
      console.log("[close] Connection closed");
      console.log(event);
      if (componentIsMounted.current) {
        setMessage("Disconnected");
        setConnectionState(null);
      }
    };
  
    socket.onmessage = function(event) {
      console.log(`[message] Data received from server: ${event.data}`);
      let data = JSON.parse(event.data);
      if (!data["message"]) {
        let status = data["status"];
        let completed = data["completed"];
        let callerId = data["caller_id"];
        let elapsedTime = data["elapsed_time"];

        console.log(`[info] lastElapsedTime: ${lastElapsedTime}`);
        if (elapsedTime && elapsedTime >= lastElapsedTime) {
          setComponentTitle("Incoming Call from " + callerId);

          if (status === "pass" && !lockDecision) {
            setMessage("Pass");
            lockDecision = true;
          } else if (status === "fail" && !lockDecision) {
            setMessage("Fail");
            lockDecision = true;
          } else if (status === "listening" && !lockDecision) {
            clearTimeout(completeTimer);
            setMessage("Listening");
          }

          lastElapsedTime = elapsedTime;
        }
    
        if (completed) {
          completeTimer = setTimeout(function(){
            setMessage("No Active Call");
            lockDecision = false;
            lastElapsedTime = 0;
            setComponentTitle("Active Call");
          }, 5000);
        }
      }
    };
  };

  function StatusIcon(props) {
    if (message === "Pass") {
      return (
        <PassIcon style={{ fontSize: '4.5rem', color: 'green' }} />
      );
    } else if (message === 'Fail') {
      return (
        <FailIcon style={{ fontSize: '4.5rem', color: 'red' }} />
      );
    } else {
      return (
        <span />
      );
    }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <div>
            <Button variant="outlined" color="secondary" size="small" onClick={handleReconnect} disabled={(connectionState != null)}>
              {buttonState(connectionState)}
            </Button>
          </div>
        }
        title={componentTitle}
      />
      <Divider />
      <CardContent align="center">
        <div className={classes.message}>
          <Grid
            container
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <StatusIcon />
            </Grid>
            <Grid item>
              {message}
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

ActiveCall.propTypes = {
  className: PropTypes.string
};

export default ActiveCall;