import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core';
import { options } from './chart';
import palette from '../../../../theme/palette';
import { API } from 'aws-amplify';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestSales = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const componentIsMounted = useRef(true);
  const [componentData, setComponentData] = useState({});

  const handleUpdate = data => {
    console.log('updating latest calls');

    var myInit = {
      headers: {},
      queryStringParameters: {},
      response: true
    };

    myInit['headers']['x-api-key'] = sessionStorage.getItem("authstate.apiKey");

    API.get('Services', '/v1/analytics/calls/latest', myInit).then(response => {
      if (response.data) {
        var body = response.data;
        var labels = [];
        var data = [];

        if (body.records) {
          for (var i = 0; i < body.records.length; i++) {
            var record = body.records[i];
            if (record) {
              labels.push(record.date);
              data.push(record.totalCalls);
            }
          }
        }

        if (componentIsMounted.current) {
          setComponentData({
            labels: labels,
            datasets: [
              {
                label: 'Total',
                backgroundColor: palette.secondary.main,
                data: data
              }
            ]
          });
        }
      }
    }).catch(error => {
        console.log('error:'+error);
    });
  };

  useEffect(() => {
    handleUpdate({
      labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug', '7 Aug'],
      data: [18, 5, 19, 5, 13, 19, 20]
    });

    return function cleanup() {
      componentIsMounted.current = false;
    };
  }, []);

  return (
    <Card
      {...rest}
      className={clsx(classes.root,className)}
    >
      <CardHeader
        title="Latest Calls"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Bar
            data={componentData}
            options={options}
          />
          <Typography
              color="inherit"
              variant="caption"
              align="center"
              display="block"
            >
              All dates are GMT.
            </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;
